import { defineStyle, defineStyleConfig } from "@chakra-ui/react"

export const Button = defineStyleConfig({
  defaultProps: { variant: "secondary", size: "sm" },
  baseStyle: {
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
  },
  sizes: {
    xs: {
      minW: "unset",
      height: "24px",
      padding: "0 8px",
      borderRadius: "8px",
      fontSize: "xs",
      fontWeight: 500,
    },
    sm: {
      minW: "unset",
      height: "32px",
      padding: "0 12px",
      borderRadius: "8px",
      fontSize: "sm",
      fontWeight: 500,
    },
    md: {
      minW: "unset",
      height: "40px",
      padding: "0 16px",
      borderRadius: "8px",
      fontSize: "md",
      fontWeight: 500,
    },
    lg: {
      minW: "unset",
      height: "48px",
      padding: "0 24px",
      borderRadius: "8px",
      fontSize: "lg",
      fontWeight: 500,
    },
    menuSm: {
      height: "30px",
      padding: "0 20px",
      fontSize: "sm",
      borderRadius: "8px",
      fontWeight: 500,
    },
    fullW: {
      height: "48px",
      padding: "0 16px",
      borderRadius: "8px",
      fontSize: "md",
      width: "100%",
      fontWeight: 500,
    },
    // Icon buttons only
    iconXs: {
      height: "16px",
      width: "16px",
    },
    iconSm: {
      height: "24px",
      width: "24px",
    },
    iconMd: {
      width: "40px",
      height: "32px",
    },
    // Custom select option sizes
    selectMd: {
      width: "100%",
      padding: "12px 16px",
      fontSize: "sm",
    },
  },
  variants: {
    primary: defineStyle({
      backgroundColor: "blue.500",
      boxShadow: "box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05)",
      color: "white",
      border: "2px solid transparent",
      _hover: {
        backgroundColor: "blue.600",
      },
      _active: {
        backgroundColor: "blue.500",
        transform: "scale(0.95)",
      },
      _focus: {
        borderColor: "blue.800",
      },
      _disabled: {
        color: "blue.300",
      },
    }),
    secondary: defineStyle({
      backgroundColor: "white",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
      border: "solid 1px",
      borderColor: "gray.200",
      _hover: {
        backgroundColor: "gray.50",
      },
      _active: {
        backgroundColor: "white",
        transform: "scale(0.95)",
      },
      _focus: {
        border: "solid 1px",
        borderColor: "blue.100",
        // Secondary button has 1 px border normally but 2px focused so we add the 2nd pixel as box shadow
        boxShadow: "0px 0px 0px 1px #DBEAFE",
      },
      _disabled: {
        color: "gray.200",
      },
    }),
    ghost: defineStyle({
      backgroundColor: "inherit",
      border: "1px solid",
      borderColor: "gray.100",
      _hover: {
        backgroundColor: "gray.100",
      },
      _active: {
        backgroundColor: "inherit",
        transform: "scale(0.95)",
      },
      _focus: {
        border: "solid 1px",
        borderColor: "blue.100",
      },
      _disabled: {
        color: "gray.200",
      },
    }),
    warning: defineStyle({
      color: "gray.800",
      backgroundColor: "orange.100",
      border: "1px solid",
      borderColor: "orange.200",
      _hover: {
        backgroundColor: "orange.200",
      },
      _active: {
        backgroundColor: "inherit",
        transform: "scale(0.95)",
      },
      _focus: {
        border: "solid 2px",
        borderColor: "blue.100",
      },
      _disabled: {
        color: "gray.200",
      },
    }),
    menu: defineStyle({
      width: "100%",
      padding: "8px",
      color: "gray.800",
      _hover: { background: "gray.100" },
      justifyContent: "start",
    }),
    iconOnly: {
      _hover: { background: "inherit" },
      _active: {
        backgroundColor: "gray.200",
      },
    },
    select: {
      lineHeight: "1",
      backgroundColor: "inherit",
      _hover: {
        backgroundColor: "gray.100",
      },
      _active: {
        backgroundColor: "inherit",
      },
      _disabled: {
        color: "gray.200",
      },
      justifyContent: "start",
      fontWeight: 400,
    },
    blockEditorIcon: defineStyle({
      minWidth: "1rem",
      minHeight: "1rem",
      height: "1.125rem",
      width: "1.5rem",
      visibility: "hidden",
      _groupFocusWithin: { visibility: "visible" },
      _groupHover: { visibility: "visible" },
      userSelect: "none",
    }),
  },
})
