import { modalAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys)

export const Drawer = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    closeButton: {
      color: "gray.500",
      backgroundColor: "gray.200",
      borderRadius: "50%",
      height: "1.5rem",
      width: "1.5rem",
    },
  }),
})
