import { Plan } from "./types"

export const BACKEND_URL: string | undefined = process.env.VITE_APP_BACKEND_URL

// Used in front end transcript upload
export const TRANSCRIPT_SETTINGS = {
  min_text_length: 20,
  max_text_length: 150000, // longest thus far w Gong close to 100k (1h)
}

export const PLANS: Plan[] = [
  { name: "free", quota: 5, price: 0, monthlyQuotaRenew: false },
  { name: "pro", quota: 20, price: 14.9, monthlyQuotaRenew: true },
  { name: "xl", quota: 100, price: 75, monthlyQuotaRenew: true },
]

const SUMMARIZE_MEETING_CONTEXT_INSTRUCTIONS =
  "Your task is to answer questions based on the above transcript.\n" +
  "For each question, first identify the parts of the conversation that are relevant to the question.\n"

const SUMMARIZE_DEAL_CONTEXT_INSTRUCTIONS =
  "Your task is to answer questions based on the above transcripts.\n" +
  "For each question, first identify the parts of the conversations that are relevant to the question.\n"

const RESPONSE_FORMAT_INSTRUCTIONS =
  "Then answer the following questions with 2-6 bullet points per question.\n" +
  "Each question number should have a corresponding answer number, followed by a line break and bullet points answering it.\n" +
  "If there is only one question, still answer in this format, i.e. '1.\\n- First point\\n- Second point\\n etc.'\n" +
  "If there is no information for a specific question, just answer with the question number and not/applicable, e.g. '2.\\n- not/applicable'.\n" +
  "Always start a new bullet point on a new row, e.g. '2.\\n- New point', not '2. - New point' or '2. New point'."

const RESPONSE_FORMAT_INSTRUCTIONS_CRM =
  "Then answer the following questions with a single paragraph or a single number for each question. " +
  "Each question number should have a corresponding answer number in square brackets, followed by the answer and then a line break. " +
  "If there is only one question, still answer in this format, i.e. '[1.] This is a single paragraph answer. " +
  "If there is no information for a specific question, just answer with the question number and not/applicable, e.g. '[2.] not/applicable'. " +
  "Here is an example full answer: \n\n" +
  "[1.] This is a single paragraph answer. [2.] 60900 [3.] This is another answer."


const RESPONSE_FORMAT_INSTRUCTIONS_FOR_DEALS =
  "Then answer the following questions with 2-10 bullet points per question.\n" +
  "Each question number should have a corresponding answer number, followed by a line break and bullet points answering it.\n" +
  "If there is only one question, still answer in this format, i.e. '1.\\n- First point\\n- Second point\\n etc.'\n" +
  "If there is no information for a specific question, just answer with the question number and not/applicable, e.g. '2.\\n- not/applicable'.\n" +
  "Always start a new bullet point on a new row, e.g. '2.\\n- New point', not '2. - New point' or '2. New point'."

export const SUMMARIZE_MEETING_SYSTEM_INSTRUCTIONS =
  SUMMARIZE_MEETING_CONTEXT_INSTRUCTIONS + RESPONSE_FORMAT_INSTRUCTIONS

export const SUMMARIZE_DEAL_SYSTEM_INSTRUCTIONS =
  SUMMARIZE_DEAL_CONTEXT_INSTRUCTIONS + RESPONSE_FORMAT_INSTRUCTIONS_FOR_DEALS

export const SUMMARIZE_CRM_SYSTEM_INSTRUCTIONS =
  SUMMARIZE_MEETING_CONTEXT_INSTRUCTIONS + RESPONSE_FORMAT_INSTRUCTIONS_CRM

export const HEADERS = {
  USE_DUMMY: "x-use-dummy",
  SIMULATE_TIME: "x-simulate-time",
}
