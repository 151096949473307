import React, { useState } from "react"
import {
  Alert,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Icon,
  useDisclosure,
} from "@chakra-ui/react"
import { Info } from "@phosphor-icons/react"
import { BotJoinOption } from "@epicbrief/shared/types"
import { botJoinOptions } from "./options"
import { ConfirmationDialog } from "./ConfirmationModal"

interface Props {
  isOpen: boolean
  onClose: () => void
  existingJoinOption: BotJoinOption
  setExistingJoinOption: (option: BotJoinOption) => void
}

interface RadioContentProps {
  title: string
  body: string
  name: string
  value: string
}

function RadioContent({ title, body, name, value }: RadioContentProps) {
  return (
    <Flex direction="column">
      <Radio size="md" name={name} value={value}>
        <Text fontSize={16} lineHeight="24px" fontWeight="500">
          {title}
        </Text>
      </Radio>
      <Text
        pl="40px"
        pt="0px"
        fontSize={14}
        lineHeight="21px"
        fontWeight="400"
        color="#6B7280"
      >
        {body}
      </Text>
    </Flex>
  )
}

export default function SettingsDrawer({
  isOpen,
  onClose,
  existingJoinOption,
  setExistingJoinOption,
}: Props) {
  const [botJoinOption, setBotJoinOption] =
    useState<BotJoinOption>(existingJoinOption)

  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onConfirmationModalOpen,
    onClose: onConfirmationModalClose,
  } = useDisclosure()

  const handleOptionChange = (option: BotJoinOption) => {
    setBotJoinOption(option)
    onConfirmationModalOpen()
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay
        background="blackAlpha.500"
        backdropFilter="auto"
        backdropBlur="8px"
      />
      <DrawerContent>
        <DrawerHeader paddingRight="0" paddingLeft="0px">
          <DrawerCloseButton top="none" />
          <Box>
            <Heading
              size="md"
              fontWeight="500"
              textColor="gray.800"
              borderBottom="1px solid"
              borderColor="gray.200"
              paddingLeft="16px"
              paddingBottom="16px"
            >
              Join Settings
            </Heading>
            <Heading
              fontSize={16}
              fontWeight="500"
              textColor="#1F2937"
              padding="16px 0px 4px 16px"
            >
              Zoom and Teams
            </Heading>
            <Text
              paddingY="2"
              paddingTop="1"
              paddingLeft="16px"
              fontSize={16}
              color="gray.800"
              fontWeight="400"
            >
              Configure Epicbrief to automatically join specific meetings based
              on your preferences.
            </Text>
          </Box>
        </DrawerHeader>

        <DrawerBody paddingTop="0px">
          <RadioGroup
            value={botJoinOption}
            onChange={(option) => handleOptionChange(option as BotJoinOption)}
          >
            <Stack direction="column">
              {botJoinOptions.map(({ value, title, body, name }) => (
                <RadioContent
                  key={value}
                  title={title}
                  body={body}
                  name={name}
                  value={value}
                />
              ))}
            </Stack>
          </RadioGroup>
          <Alert
            status="info"
            marginTop="5"
            fontWeight="400"
            fontSize={14}
            borderRadius="6"
          >
            <Icon
              boxSize="18px"
              as={Info}
              marginRight="18"
              marginBottom="39"
              color="blue.500"
            />
            You can always enable or disable Epicbrief to join specific meetings
            in home page.
          </Alert>
        </DrawerBody>
      </DrawerContent>
      <ConfirmationDialog
        selectedOption={botJoinOption}
        setSelectedOption={setBotJoinOption}
        existingJoinOption={existingJoinOption}
        setExistingJoinOption={setExistingJoinOption}
        isOpen={isConfirmationModalOpen}
        onClose={onConfirmationModalClose}
      />
    </Drawer>
  )
}
