import React, { RefObject, useState, useRef } from "react"
import {
  Button,
  Flex,
  Text,
  Icon,
  Alert,
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogFooter,
} from "@chakra-ui/react"

import { Warning, WarningCircle } from "@phosphor-icons/react"

import { BotJoinOption } from "@epicbrief/shared/types"
import { botJoinOptions } from "./options"
import { useBotJoinOption } from "./useBotJoinOption"
import { toastError, toastSuccess } from "../../../utils/toast"

interface Props {
  isOpen: boolean
  onClose: () => void
  selectedOption: BotJoinOption
  setSelectedOption: (option: BotJoinOption) => void
  existingJoinOption: BotJoinOption
  setExistingJoinOption: (option: BotJoinOption) => void
}

export function ConfirmationDialog({
  isOpen,
  onClose,
  selectedOption,
  setSelectedOption,
  existingJoinOption,
  setExistingJoinOption,
}: Props) {
  const selected = botJoinOptions.find(({ value }) => value === selectedOption)
  const cancelRef = useRef() as React.MutableRefObject<HTMLButtonElement>
  const { updateBotJoinOption } = useBotJoinOption()
  const [isApplyLoading, setIsApplyLoading] = useState(false)

  const handleOnClose = () => {
    setSelectedOption(existingJoinOption)
    onClose()
  }

  const onApply = () => {
    setIsApplyLoading(true)
    updateBotJoinOption(selectedOption)
      .then((isOk) => {
        if (isOk) {
          setExistingJoinOption(selectedOption)
          toastSuccess({ title: `Settings applied successfully` })
        } else {
          setSelectedOption(existingJoinOption)
          toastError("Failed to update join settings")
        }
        onClose()
      })
      .finally(() => setIsApplyLoading(false))
  }

  return (
    <AlertDialog
      isCentered
      leastDestructiveRef={cancelRef as RefObject<any>}
      onClose={handleOnClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent padding="0">
        <AlertDialogHeader padding="12px 16px 12px 16px">
          <Flex direction="row" alignItems="center">
            <Icon boxSize="18px" as={Warning} weight="bold" color="gray.500" />
            <Text fontSize={18} fontWeight="500" lineHeight="28px">
              Join Settings
            </Text>
            <AlertDialogCloseButton top="none" />
          </Flex>
        </AlertDialogHeader>

        <AlertDialogBody p="16px">
          <Flex direction="column">
            <Text
              fontWeight="500"
              fontSize={15}
              paddingLeft="0px"
              paddingTop="0px"
            >
              Epicbrief joins: {selected?.title}
            </Text>
            <Text
              fontWeight="400"
              fontSize={14}
              paddingLeft="0px"
              paddingTop="0px"
            >
              {selected?.confirmationBody}
            </Text>
            <Alert
              padding="8px 16px 8px 16px"
              fontWeight="400"
              fontSize={14}
              background="orange.50"
              borderRadius="6"
              status="warning"
            >
              <Icon
                as={WarningCircle}
                boxSize="18px"
                marginRight="9px"
                marginBottom="23"
                color="orange.500"
              />
              Once you click the Apply button, this setting will be applied
              immediately!
            </Alert>
          </Flex>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            height="40px"
            marginRight={3}
            onClick={handleOnClose}
            ref={cancelRef}
          >
            Close
          </Button>
          <Button
            height="40px"
            isLoading={isApplyLoading}
            loadingText="Applying"
            onClick={onApply}
          >
            Apply
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
