import React from "react"
import { Container, Flex } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"
import MainMenu from "../Components/MainMenu"

/**
 * Use together with ./FixedTopSection.tsx and ./ScrollableMainSection.tsx
 * in the page to determine the border of fixed and scollable.
 */

export default function FixedTopSectionLayout() {
  return (
    <Container minHeight="100vh" maxWidth="100%" height="100vh">
      <Flex flex={1}>
        <MainMenu />
        <Flex direction="column" flex={1}>
          <Outlet />
        </Flex>
      </Flex>
    </Container>
  )
}
