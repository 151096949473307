import * as React from "react"

export function EpicbriefLogo({
  height,
  color,
}: {
  height?: string
  color?: string
}) {
  const fillOpacity = "0.9"
  const fillColor = color ?? "black"
  const iconHeight = height ?? "32"

  return (
    <svg
      height={iconHeight}
      viewBox="0 0 178 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.625793 0H30.2258V12.8H0.625793V0Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M0.625793 15.2002H30.2258V24.0002H0.625793V15.2002Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M0.625793 26.4004H30.2258V32.0004H0.625793V26.4004Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M50.0285 26.0479C53.5485 26.0479 56.5245 24.3839 58.1245 21.5039L53.2925 20.2239C52.4925 21.0879 51.4045 21.5359 50.2205 21.5359C48.2045 21.5359 46.8605 20.6399 46.4765 19.1039H58.4445C58.5085 18.7199 58.5405 18.2079 58.5405 17.5039C58.5405 12.6719 54.7325 8.99185 49.7085 8.99185C44.6845 8.99185 40.8445 12.6719 40.8445 17.5039C40.8445 22.4959 44.6525 26.0479 50.0285 26.0479ZM46.5405 15.7439C46.9245 14.3679 48.1725 13.4719 49.7725 13.4719C51.3405 13.4719 52.5245 14.3679 52.8765 15.7439H46.5405Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M60.5998 31.9999H66.1358V24.8639C66.9998 25.5999 68.4078 26.0479 69.7838 26.0479C74.2638 26.0479 77.6878 22.5599 77.6878 17.5039C77.6878 12.4479 74.2638 8.99185 69.7838 8.99185C68.4078 8.99185 66.9998 9.43985 66.1358 10.1439V9.43985H60.5998V31.9999ZM66.1358 19.9039V15.1359C66.7758 14.5919 67.7678 14.3359 68.7598 14.3359C70.7758 14.3359 72.1518 15.5519 72.1518 17.5039C72.1518 19.4879 70.7758 20.7039 68.7598 20.7039C67.7678 20.7039 66.7758 20.4159 66.1358 19.9039Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M79.7248 25.5999H85.2608V9.43985H79.7248V25.5999ZM79.2768 5.18385C79.2768 7.00785 80.6848 8.38385 82.5088 8.38385C84.3328 8.38385 85.7088 6.97585 85.7088 5.18385C85.7088 3.45585 84.3008 2.04785 82.5088 2.04785C80.7168 2.04785 79.2768 3.42385 79.2768 5.18385Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M96.1765 26.0479C100.273 26.0479 103.601 23.5839 104.657 20.0639L99.2805 18.6559C98.8645 19.9039 97.7125 20.7039 96.1765 20.7039C94.2245 20.7039 92.8485 19.3599 92.8485 17.5039C92.8485 15.6479 94.2245 14.3359 96.1765 14.3359C97.7125 14.3359 98.8645 15.1359 99.2805 16.3839L104.657 14.9759C103.601 11.4239 100.273 8.99185 96.1765 8.99185C91.1525 8.99185 87.3125 12.7039 87.3125 17.5039C87.3125 22.3359 91.1525 26.0479 96.1765 26.0479Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M115.846 26.0479C120.326 26.0479 123.75 22.5599 123.75 17.5039C123.75 12.4479 120.326 8.99185 115.846 8.99185C114.47 8.99185 113.062 9.43985 112.198 10.1439V3.19985H106.662V25.5999H112.198V24.8639C113.062 25.5999 114.47 26.0479 115.846 26.0479ZM112.198 19.9039V15.1359C112.838 14.5919 113.83 14.3359 114.822 14.3359C116.838 14.3359 118.214 15.5519 118.214 17.5039C118.214 19.4879 116.838 20.7039 114.822 20.7039C113.83 20.7039 112.838 20.4159 112.198 19.9039Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M125.819 25.5999H131.355V17.7279C131.355 15.7439 132.603 14.7839 135.131 14.7839H136.347V9.43985H135.355C133.467 9.43985 132.027 10.1759 131.355 11.4559V9.43985H125.819V25.5999Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M138.444 25.5999H143.98V9.43985H138.444V25.5999ZM137.996 5.18385C137.996 7.00785 139.404 8.38385 141.228 8.38385C143.052 8.38385 144.428 6.97585 144.428 5.18385C144.428 3.45585 143.02 2.04785 141.228 2.04785C139.436 2.04785 137.996 3.42385 137.996 5.18385Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M155.247 26.0479C158.767 26.0479 161.743 24.3839 163.343 21.5039L158.511 20.2239C157.711 21.0879 156.623 21.5359 155.439 21.5359C153.423 21.5359 152.079 20.6399 151.695 19.1039H163.663C163.727 18.7199 163.759 18.2079 163.759 17.5039C163.759 12.6719 159.951 8.99185 154.927 8.99185C149.903 8.99185 146.063 12.6719 146.063 17.5039C146.063 22.4959 149.871 26.0479 155.247 26.0479ZM151.759 15.7439C152.143 14.3679 153.391 13.4719 154.991 13.4719C156.559 13.4719 157.743 14.3679 158.095 15.7439H151.759Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M168.03 8.60785V9.43985H165.47V14.7839H168.03V25.5999H173.566V14.7839H177.374V9.43985H173.566V8.95985C173.566 7.99985 173.918 7.67985 174.974 7.67985H177.374V3.19985H174.046C169.79 3.19985 168.03 4.79985 168.03 8.60785Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
    </svg>
  )
}
