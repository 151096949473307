import { BotJoinOption } from "@epicbrief/shared/types"

export const botJoinOptions = [
  {
    title: "All meetings",
    body: "Epicbrief joins all meetings unless you manually disable from home page",
    name: BotJoinOption.ALL_MEETINGS,
    value: BotJoinOption.ALL_MEETINGS,
    confirmationBody:
      "Epicbrief will join all meetings - those with participants from outside or inside your organization.",
  },
  {
    title: "Internal meetings only",
    body: "Joins meetings with participants from only your organization",
    name: BotJoinOption.INTERNAL_MEETINGS_ONLY,
    value: BotJoinOption.INTERNAL_MEETINGS_ONLY,
    confirmationBody:
      "Epicbrief will only join meetings with people from your organization",
  },
  {
    title: "External meetings only",
    body: "Joins meetings with participants from outside your organization",
    name: BotJoinOption.EXTERNAL_MEETINGS_ONLY,
    value: BotJoinOption.EXTERNAL_MEETINGS_ONLY,
    confirmationBody:
      "Epicbrief will only join meetings with people from outside your organization",
  },
  {
    title: "None",
    body: "Epicbrief does not join any meetings unless you manually enable from home page",
    name: BotJoinOption.NONE,
    value: BotJoinOption.NONE,
    confirmationBody: "Epicbrief will not join any of your meetings",
  },
]
