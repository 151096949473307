import { useContext, useState } from "react"
import { BotJoinOption } from "@epicbrief/shared/types"
import AuthContext from "../../../AuthContext"
import { getApiClient } from "../../../api"

export const useBotJoinOption = () => {
  const { currentUser, currentUserData } = useContext(AuthContext)
  const apiClient = getApiClient(currentUser!)
  const [botJoinOption, setBotJoinOption] = useState<BotJoinOption>(
    currentUserData?.botJoinOption || BotJoinOption.ALL_MEETINGS,
  )

  const updateBotJoinOption = async (updatedBotJoinOption: BotJoinOption) => {
    const isOk = await apiClient.users.updateBotJoinOption(updatedBotJoinOption)
    return !!isOk
  }

  return {
    botJoinOption,
    setBotJoinOption,
    updateBotJoinOption,
  }
}
