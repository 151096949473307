import { Flex, Spinner } from "@chakra-ui/react"
import React, { useContext, useEffect, useState } from "react"
import {
  Navigate,
  useLocation,
  useNavigate,
  useMatch,
  Outlet,
} from "react-router-dom"
import { usePostHog } from "posthog-js/react"
import AuthContext from "../AuthContext"
import { users } from "../controllers/database"
import FullHeightScrollLayout from "../Layouts/FullHeightScrollLayout"
import OnboardingLayout from "../Layouts/OnboardingLayout"
import FixedTopSectionLayout from "../Layouts/FixedTopSectionLayout"
import { ROUTES } from "../utils/constants"
import { getApiClient } from "../api"

const onboardingRoutes = [
  ROUTES.CONNECT_VIDEO,
  ROUTES.CONNECT_CALENDAR,
  ROUTES.CONNECT_CHROME,
]

const singleMeetingPagePaths = Object.values(ROUTES.MEETING)

function ProtectedRoute() {
  const [isLoaded, setIsLoaded] = useState(false)
  const { currentUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  // Normalize pathname to always match an app route
  const route = location.pathname.endsWith("/")
    ? location.pathname.slice(0, -1)
    : location.pathname

  const isOnboardingRoute = onboardingRoutes.includes(route)

  const pathsMatchRoute = singleMeetingPagePaths.map((path) => useMatch(path))
  const isSingleMeetingPage = pathsMatchRoute.some((match) => !!match)

  const isAuthCallback = useMatch("auth-callback")

  const { refreshUserAndOrg } = useContext(AuthContext)

  const posthog = usePostHog()

  useEffect(() => {
    const maybeStartOnboarding = async () => {
      if (currentUser) {
        const userData = await users.get(currentUser.uid)

        if (!userData?.userOnboarding) {
          const apiClient = getApiClient(currentUser)
          await apiClient.orgs.joinOrg()

          await refreshUserAndOrg()

          navigate(ROUTES.CONNECT_CALENDAR)
        }

        setIsLoaded(true)
      }
    }

    if (currentUser) {
      maybeStartOnboarding()
    } else {
      setIsLoaded(true)
    }
  }, [])

  useEffect(() => {
    if (currentUser) {
      posthog?.identify(currentUser.uid, {
        email: currentUser.email,
        displayName: currentUser.displayName,
      })
    }
  }, [
    posthog,
    currentUser?.displayName,
    currentUser?.email,
    currentUser?.displayName,
  ])

  if (!currentUser) {
    return <Navigate to="/login" replace />
  }
  if (!isLoaded) {
    return (
      <Flex w="100vw" h="100vh" justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    )
  }
  if (isOnboardingRoute) {
    return <OnboardingLayout />
  }
  if (isSingleMeetingPage) {
    return <FixedTopSectionLayout />
  }
  if (isAuthCallback) {
    return <Outlet />
  }
  return <FullHeightScrollLayout />
}

export default React.memo(ProtectedRoute)
