import { get } from "lodash-es"
import * as Sentry from "@sentry/react"
import { FieldErrors, FieldValues } from "react-hook-form"
import { isTestEnvironment } from "@epicbrief/shared/utils/config"

export const initSentry = () => {
  const { VITE_APP_SENTRY_DSN: SENTRY_DSN } = process.env

  if (!SENTRY_DSN) return

  Sentry.init({
    dsn: SENTRY_DSN,
    // 100% chance of sending traces for now
    tracesSampleRate: 1.0,
  })
}

export const reportError = (error: Error) => {
  if (!isTestEnvironment()) Sentry.captureException(error)
  console.error(error)
}

/**
 * Higher order function to wrap any async function in try-catch.
 *
 * consoler.errors in catch block atm although trivial to extend to make that optional or log to sentry.
 *
 * @param func - any async function
 * @returns The original function wrapped in try-catch
 */
type AsyncFunction<Arguments extends any[], Response> = (
  ...args: Arguments
) => Promise<Response>

export function withCatch<Arguments extends any[], Response>(
  asyncFunction: AsyncFunction<Arguments, Response>,
): AsyncFunction<Arguments, Response | null> {
  return async (...args: Arguments): Promise<Response | null> => {
    try {
      return await asyncFunction(...args)
    } catch (error) {
      Sentry.captureException(error)
      console.error(error)
      return null
    }
  }
}

/**
 * Retrieves the error message from a nested errors object using a field key.
 * @param errors FieldErrors<FieldValues>
 * @param fieldKey string
 * @returns string | undefined
 */
export function getNestedErrorMessage(
  errors: FieldErrors<FieldValues>,
  fieldKey: string,
) {
  const nestedError = get(errors, fieldKey)
  return nestedError?.message?.toString()
}
