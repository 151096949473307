import * as React from "react"

export function EpicbriefLogoMobile({
  height,
  color,
}: {
  height?: string
  color?: string
}) {
  const fillOpacity = "0.9"
  const fillColor = color ?? "black"
  const iconHeight = height ?? "32"

  return (
    <svg
      height={iconHeight}
      viewBox="0 0 178 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.625793 0H30.2258V12.8H0.625793V0Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M0.625793 15.2002H30.2258V24.0002H0.625793V15.2002Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M0.625793 26.4004H30.2258V32.0004H0.625793V26.4004Z"
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
    </svg>
  )
}
