import React, { useContext } from "react"
import { Tab, TabList, Tabs } from "@chakra-ui/react"
import { useLocation } from "react-router-dom"
import { ROUTES } from "../utils/constants"
import StyledNavLink from "./StyledNavLink"
import AuthContext from "../AuthContext"

const getTabs = (currentUserIsOrgAdmin: boolean) => {
  const tabs = [
    { label: "Integrations", targetRoute: ROUTES.SETTINGS.INTEGRATIONS },
  ]
  if (currentUserIsOrgAdmin) {
    tabs.push(
      {
        label: "User Management",
        targetRoute: ROUTES.SETTINGS.MANAGE_USERS,
      },
      {
        label: "CRM Fields Mapping",
        targetRoute: ROUTES.SETTINGS.CRM_FIELDS_MAPPING,
      },
    )
  }
  return tabs
}

export default function SettingsTabs() {
  const location = useLocation()

  const { currentUserIsOrgAdmin } = useContext(AuthContext)
  const TABS = getTabs(currentUserIsOrgAdmin)

  const currentTabIndex = TABS.findIndex((tab) =>
    location.pathname.startsWith(tab.targetRoute),
  )
  // default to 0 if none found
  const activeTabIndex = currentTabIndex === -1 ? 0 : currentTabIndex

  return (
    <Tabs index={activeTabIndex}>
      <TabList>
        {TABS.map(({ label, targetRoute }) => (
          <Tab key={targetRoute} as={StyledNavLink} to={targetRoute}>
            {label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  )
}
