import { Flex, Box } from "@chakra-ui/react"
import React from "react"
import { Outlet } from "react-router-dom"
import Header from "../Components/Header"
import Footer from "../Components/Footer"

export default function BlueLayout() {
  return (
    <Flex
      minHeight="100vh"
      layerStyle="bluebg"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Header />
      <Box flex="1 0 auto" as="main">
        <Outlet />
      </Box>
      <Footer />
    </Flex>
  )
}
