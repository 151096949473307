export interface GoogleAuthCredentials {
  refresh_token?: string | null
  expiry_date?: number | null
  access_token?: string | null
  token_type?: string | null
  id_token?: string | null
  scope?: string
}

export interface GoogleCredentials {
  userId: string | undefined
  tokens: GoogleAuthCredentials
}

export interface GoogleAuth {
  tokens: GoogleCredentials
  timestamp: Date
}

export interface SalesforceAuthCredentials {
  accessToken: string
  refreshToken: string
  expiresIn?: number
  instanceUrl: string
  issuedAt: number
}

export interface HubspotAuthCredentials {
  accessToken: string
  refreshToken: string
  expiresIn: number
  issuedAt: number
  instanceUrl?: string
}

export interface CrmConnection {
  service: "salesforce" | "hubspot" // | "hubspot" etc.
  crmUserId: string
  crmHubId?: string
  session: SalesforceAuthCredentials | HubspotAuthCredentials
  replayId?: number
}

export interface GongConnection {
  accessToken: string
  refreshToken: string
  instanceUrl: string
}

export interface OutlookCalendarConnection {
  type: "outlook"
  auth: {
    sub: string
    timestamp: string
    tokens: MicrosoftAuthCredentials
  }
}

export interface MicrosoftAuthCredentials {
  error?: string | null
  error_description?: string | null
  refresh_token?: string | null
  expiry_date?: number | null
  access_token?: string | null
  token_type?: string | null
  id_token?: string | null
  scope?: string
}

export interface GoogleCalendarConnection {
  type: "google"
  auth: {
    googleUserId: string
    timestamp: string
    tokens: GoogleAuthCredentials
  }
}

type RecallCalendarStatusChange = {
  status: string
  created_at: string
  reason?: string // Present when an error occurs
}

// Connection between a user calendar and Recall
export interface RecallCalendarConnection {
  id: string
  userRef: string
  raw: {
    // https://recallai.readme.io/reference/calendars_create
    id: string
    platform: "google_calendar" | "microsoft_outlook"
    oauth_client_id: string
    oauth_client_secret: string
    oauth_refresh_token: string
    webhook_url: string
    oauth_email: string | null
    platform_email: string | null
    status: string
    status_changes: RecallCalendarStatusChange[]
    created_at: string
    updated_at: string
  }
}

export interface RecallBotConfig {
  name: string
  language:
    | "en-US"
    | "en-GB"
    | "fr-FR"
    | "de-DE"
    | "es-US"
    | "pt-BR"
    | "it-IT"
    | "da-DK"
    | "et-ET"
    | "nl-NL"
    | "fi-FI"
    | "pl-PL"
    | "sv-SE"
    | "ro-RO"
    | "no-NO"
    | "ar-SA"
    | "zh-CN"
    | "cs-CZ"
    | "he-IL"
    | "hi-IN"
    | "hu-HU"
    | "id-ID"
    | "ja-JP"
    | "ko-KR"
    | "ms-MY"
    | "ru-RU"
    | "tl-PH"
    | "ta-IN"
    | "te-IN"
    | "th-TH"
    | "tr-TR"
    | "uk-UA"
    | "vi-VN"
    | "el-GR"
    | "sk-SK"
    | "cy-WL"
  askToJoinConfig: "always" | "never"
}

export enum RecallMeetingPlatform {
  ZOOM = "zoom",
  GOOGLE_MEET = "google_meet",
  MICROSOFT_TEAMS = "microsoft_teams",
  WEBEX = "webex",
}

export enum RecallCalendarPlatform {
  MICROSOFT_OUTLOOK = "microsoft_outlook",
  GOOGLE_CALENDAR = "google_calendar",
}

interface RecallCalendarEventBot {
  bot_id: string
  start_time: string // date-time format
  deduplication_key: string
  meeting_url: string
}

export interface GoogleCalendarEventAttendee {
  self?: boolean
  email?: string
  displayName?: string
  organizer?: boolean
  responseStatus?: string
}

export interface OutlookCalendarEventAttendee {
  type?: string
  status?: OutlookCalendarEventAttendeeStatus
  emailAddress?: OutlookCalendarEventAttendeeEmailAddress
}

export interface OutlookCalendarEventAttendeeStatus {
  response?: string
  time?: string
}

export interface OutlookCalendarEventAttendeeEmailAddress {
  name?: string
  address?: string
}

export interface RecallGoogleMeetingRaw {
  id?: string
  end?: {
    dateTime?: string
    timeZone?: string
  }
  etag?: string
  kind?: string
  start?: {
    dateTime?: string
    timeZone?: string
  }
  status?: string
  created?: string
  creator?: {
    self?: boolean
    email?: string
  }
  iCalUID?: string
  summary?: string
  updated?: string
  htmlLink?: string
  location?: string
  sequence?: number
  eventType?: string
  attendees?: GoogleCalendarEventAttendee[]
  organizer?: GoogleCalendarEventOrganizer
  reminders?: {
    useDefault?: boolean
  }
  description?: string
  extendedProperties?: {
    shared?: {
      zmMeetingNum?: string
      zmCreateEventType?: string
      ZOOM_ConferenceNumber?: string
      ZOOM_ConferencePassword?: string
    }
  }
}

export interface RecallTeamsMeetingRaw {
  id?: string
  end?: {
    dateTime?: string
    timeZone?: string
  }
  start?: {
    dateTime?: string
    timeZone?: string
  }
  createdDateTime?: string
  iCalUID?: string
  subject?: string
  location?: {
    uniqueId?: string
    displayName?: string
    locationType?: string
    uniqueIdType?: string
  }
  attendees?: OutlookCalendarEventAttendee[]
  organizer?: OutlookCalendarEventOrganizer
  isReminderOn?: boolean
  onlineMeetingProvider?: string
  isCancelled?: boolean
  importance?: string
}

export interface OutlookCalendarEventOrganizer {
  emailAddress: OutlookCalendarEventAttendeeEmailAddress
}

export interface GoogleCalendarEventOrganizer {
  self?: boolean
  email?: string
}

export interface RecallCalendarEvent {
  id: string
  meetingRef?: string
  isBotDisabled?: boolean
  meetingLanguage?: RecallBotConfig["language"]
  raw: {
    id: string
    start_time: string // date-time format
    end_time: string // date-time format
    calendar_id: string
    raw?: RecallGoogleMeetingRaw | RecallTeamsMeetingRaw
    platform: string
    platform_id: string
    ical_uid: string
    meeting_platform: RecallMeetingPlatform
    meeting_url: string | ""
    created_at: string // date-time format
    updated_at: string // date-time format
    is_deleted: boolean
    bots: Array<RecallCalendarEventBot>
  }
}

export interface ScheduledRecallMeeting {
  id: string
  start_time: string // date-time format
  end_time: string // date-time format
  calendar_id: string
  raw?: RecallGoogleMeetingRaw | RecallTeamsMeetingRaw
  platform: string
  platform_id: string
  ical_uid: string
  meeting_platform: RecallMeetingPlatform
  meeting_url: string | ""
  created_at: string // date-time format
  updated_at: string // date-time format
  is_deleted: boolean
  bots: Array<RecallCalendarEventBot>
}

// Connection between Zoom user and Recall
export interface RecallZoomConnection {
  // https://recallai.readme.io/reference/zoom_oauth_credentials_create
  id: string
  oauth_app: string
  status: "healthy" | "unhealthy"
  user_id: string
  account_id: string | null
  access_token_callback_url: string | null
  created_at: string // This should be a valid ISO 8601 date-time string
}

export interface TeamsConnection {
  enabled?: boolean
  botConfig?: RecallBotConfig
}

export interface SlackAuth {
  access_token?: string
  expires_in?: number
  id?: string
  refresh_token?: string
  scope?: string
  token_type?: string
}

export interface SlackConnection {
  auth: SlackAuth
}

export enum CrmOnboardingStep {
  NOT_CONNECTED = "notConnected", // user has no crm connection
  BEFORE_SYNC = "beforeSync", // user has crm connection, and we are fetching objects
  SYNC = "sync", // we fetched objects, and user needs to merge them
  CONNECTED_AND_SYNCED = "connectedAndSynced", // user merged objects, everything is in sync
}
