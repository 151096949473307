import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  deleteField,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  onSnapshot,
  PartialWithFieldValue,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
  where,
  WhereFilterOp,
} from "firebase/firestore"
import {
  Account,
  CrmMapping,
  CrmTemplate,
  Deal,
  EnrichLookup,
  Meeting,
  Org,
  PrepTemplate,
  Product,
  SummaryTemplate,
  SysSummary,
  Transcript,
  UserData,
} from "@epicbrief/shared/types/index"
import { firestore } from "../firebase-config"

interface WithId {
  id: string
}

interface Filter<T> {
  field: keyof T
  opStr: WhereFilterOp
  value: unknown
}

// Helper to type collection function returns
const createCollection = <W>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<W>
}

/**
 *
 * Creates a controller object for the collection, with typed operations as properties
 *
 * About typings:
 * T represents the full object with id included
 * To keep typescript intelligence, use Omit<T, "id"> to represent the entity without id.
 * All Document and Collection references therefore use Omit<T, "id"> and entity return should be T.
 *
 * @param collectionName
 * @returns
 */
const createCollectionController = <T extends WithId>(
  collectionName: string,
) => {
  const collectionInstance = createCollection<Omit<T, "id">>(collectionName)

  return {
    /**
     * Fetch a document by id
     * @param id
     */
    get: async (id: T["id"]): Promise<T | null> => {
      const docRef = doc(firestore, collectionName, id) as DocumentReference<
        Omit<T, "id">
      >
      const document = await getDoc(docRef)
      if (document.exists()) return { id: document.id, ...document.data() } as T
      return null
    },
    /**
     * Create a new document. Use upsert method if you want to force the id.
     * @param data Omit<T, "id">
     * @returns database created id
     */
    add: async (data: Omit<T, "id">): Promise<T["id"]> => {
      const document = await addDoc(collectionInstance, data)
      return document.id
    },
    /**
     * Update an existing document or create a new with the provided id if one doesn't already exist.
     * Only updates fields, doesn't remove other existing fields.
     * @param document Partial<T> with id mandatory.
     */
    upsert: async (document: PartialWithFieldValue<T>): Promise<void> => {
      const { id, ...data } = document
      const docRef = doc(
        firestore,
        collectionName,
        id as T["id"],
      ) as DocumentReference<Omit<T, "id">>
      const snapshot = await getDoc(docRef)
      // The merge functionality in setDoc is not transactional and causes get
      // operations to return partials, hence the if else
      if (snapshot.exists()) {
        await updateDoc(docRef, data)
      } else {
        await setDoc(docRef, data as PartialWithFieldValue<Omit<T, "id">>, {
          merge: true,
        })
      }
    },
    /**
     * Update an existing document..
     * Only updates fields, doesn't remove other existing fields.
     * @param document Partial<T> with id mandatory.
     */
    update: async (document: PartialWithFieldValue<T>): Promise<void> => {
      const { id, ...data } = document
      const docRef = doc(
        firestore,
        collectionName,
        id as T["id"],
      ) as DocumentReference<Omit<T, "id">>
      await updateDoc(docRef, data)
    },
    /**
     * Delete a single field from a record
     * @param id string
     * @param field keyof T
     */
    deleteField: async (id: T["id"], field: keyof T): Promise<void> => {
      const docRef = doc(firestore, collectionName, id) as DocumentReference<
        Omit<T, "id">
      >
      await updateDoc(docRef, { [field]: deleteField() })
    },
    /**
     * Permanently delete an entire document. Use deleteField to delete a single field only.
     * @param id
     */
    delete: async (id: T["id"]): Promise<void> => {
      const docRef = doc(firestore, collectionName, id) as DocumentReference<
        Omit<T, "id">
      >
      await deleteDoc(docRef)
    },
    /**
     * List all documents
     * @returns T[]
     */
    list: async (): Promise<T[]> => {
      const snapshot = await getDocs(collectionInstance)
      return snapshot.docs.map((document) => {
        const { id } = document
        const data = document.data()
        return { id, ...data } as T
      })
    },
    /**
     * @param filters any amount of Filter<T> comma separated
     * @returns  T[]
     */
    listFiltered: async (...filters: Filter<T>[]): Promise<T[]> => {
      const constraints = filters.map((filter) => {
        const { field, opStr, value } = filter
        return where(field as string, opStr, value)
      })
      const snapshot = await getDocs(query(collectionInstance, ...constraints))
      return snapshot.docs.map((document) => {
        const { id } = document
        const data = document.data()
        return { id, ...data } as T
      })
    },
    subscribeToListFiltered: async (
      callback: (documents: T[]) => void,
      ...filters: Filter<T>[]
    ): Promise<Unsubscribe> => {
      const constraints = filters.map((filter) => {
        const { field, opStr, value } = filter
        return where(field as string, opStr, value)
      })

      return onSnapshot(
        query(collectionInstance, ...constraints),
        (querySnapshot) => {
          const mappedDocuments = querySnapshot.docs.map((document) => {
            const { id } = document
            const data = document.data()
            return { id, ...data } as T
          })
          callback(mappedDocuments)
        },
      )
    },
  }
}

const users = createCollectionController<UserData>("users")
const meetings = createCollectionController<Meeting>("meetings")
const accounts = createCollectionController<Account>("accounts")
const summaryTemplates =
  createCollectionController<SummaryTemplate>("summaryTemplates")
const transcripts = createCollectionController<Transcript>("transcripts")
const orgs = createCollectionController<Org>("orgs")
const sysSummaries = createCollectionController<SysSummary>("sysSummaries")
const deals = createCollectionController<Deal>("deals")
const crmTemplates = createCollectionController<CrmTemplate>("crmTemplates")
const enrichLookup = createCollectionController<EnrichLookup>("enrichLookup")
const prepTemplates = createCollectionController<PrepTemplate>("prepTemplates")
const userProducts = createCollectionController<Product>("userProducts")
const crmMappings = createCollectionController<CrmMapping>("crmMappings")

export {
  users,
  meetings,
  accounts,
  summaryTemplates,
  transcripts,
  orgs,
  sysSummaries,
  deals,
  crmTemplates,
  enrichLookup,
  prepTemplates,
  userProducts,
  crmMappings,
}
