import { FeatureFlagKey } from "@epicbrief/shared/types"
import { isTestRun } from "@epicbrief/shared/utils/config"
import posthog from "posthog-js"
import { reportError } from "./errors"

export const useFeatureFlag = (featureFlag: FeatureFlagKey): boolean => {
  if (isTestRun()) return true
  const isActive = posthog.isFeatureEnabled(featureFlag)
  if (isActive === undefined) {
    reportError(new Error(`Feature flag ${featureFlag} not found.`))
  }
  return !!isActive
}
