import { Button, Image, Spacer } from "@chakra-ui/react"
import { WarningCircle } from "@phosphor-icons/react"
import React from "react"
import { NavLinkProps } from "react-router-dom"
import { v4 as uuid } from "uuid"
import { useViewport } from "../utils/helpers"
import StyledNavLink from "./StyledNavLink"

export interface MenuItem {
  label: string
  icon?: JSX.Element
  action?: () => void
  isActive?: boolean
  linkTo?: NavLinkProps["to"]
  notificationLabel?: "warning"
}

const getRightIcon = (notificationLabel?: "warning") => {
  switch (notificationLabel) {
    case "warning": {
      return <Image as={WarningCircle} color="orange.500" />
    }
    default: {
      return undefined
    }
  }
}

export default function MenuItems({ menuItems }: { menuItems: MenuItem[] }) {
  const { isMobile } = useViewport()
  return (
    <>
      {menuItems.map(
        ({ label, icon, action, isActive, linkTo, notificationLabel }) => {
          const rightIcon = getRightIcon(notificationLabel)

          return (
            <Button
              w="100%"
              key={uuid()}
              fontWeight="medium"
              variant="menu"
              size="menuSm"
              onClick={action}
              leftIcon={icon}
              rightIcon={rightIcon}
              background={isActive ? "gray.200" : undefined}
              _hover={!isActive ? { background: "gray.100" } : undefined}
              as={StyledNavLink}
              to={linkTo ?? ""}
            >
              {isMobile ? "" : label}
              <Spacer />
            </Button>
          )
        },
      )}
    </>
  )
}
