import * as anatomy from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

// @ref https://chakra-ui.com/docs/components/menu/theming
const {
  definePartsStyle: defineMenuPartsStyle,
  defineMultiStyleConfig: defineMenuMultiStyleConfig,
} = createMultiStyleConfigHelpers(anatomy.menuAnatomy.keys)

export const Menu = defineMenuMultiStyleConfig({
  variants: {
    popover: defineMenuPartsStyle({
      button: {},
      list: {
        width: "12rem",
        minWidth: "12rem",
        borderRadius: "0.5rem",
        padding: 0,
        boxShadow:
          "0px 0px 1px rgba(45, 55, 72, 0.05), 0px 2px 4px rgba(45, 55, 72, 0.1);",
      },
      item: {
        iconSpacing: "2",
        fontSize: "xs",
        fontWeight: 500,
        height: "2rem",
        pl: "0.75rem",
        _focus: { backgroundColor: "gray.50" },
        _notLast: {
          borderBottom: "solid 1px",
          borderColor: "gray.200",
        },
        _first: {
          borderTopLeftRadius: "0.5rem",
          borderTopRightRadius: "0.5rem",
        },
        _last: {
          borderBottomLeftRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem",
        },
      },
    }),
    select: defineMenuPartsStyle({
      button: {},
      list: {
        width: "400px",
        minWidth: "400px",
        borderRadius: "0.5rem",
        padding: 0,
        boxShadow:
          "0px 0px 1px rgba(45, 55, 72, 0.05), 0px 2px 4px rgba(45, 55, 72, 0.1);",
      },
      groupTitle: { color: "gray.400" },
      divider: {
        color: "gray.500",
      },
      item: {
        iconSpacing: "2",
        fontSize: "sm",
        height: "2rem",
        pl: "0.75rem",
        _focus: { backgroundColor: "gray.50" },
        _notLast: {
          borderBottom: "solid 1px",
          borderColor: "gray.200",
        },
        _first: {
          borderTopLeftRadius: "0.5rem",
          borderTopRightRadius: "0.5rem",
        },
        _last: {
          borderBottomLeftRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem",
        },
      },
    }),
  },
})
