export const TEST_ENVIRONMENTS = ["epicbrief-dev", "epicbrief-sta"]

// true if running as a test process
export const isTestRun = () => {
  return process.env.NODE_ENV === "test"
}

// true if running as a test process or in a test environment
export const isTestEnvironment = () => {
  if (isTestRun()) return true

  const currentEnvironment =
    process.env.VITE_APP_FIREBASE_PROJECT_ID ??
    process.env.FIREBASE_PROJECT_ID ??
    "unknown"
  return TEST_ENVIRONMENTS.includes(currentEnvironment)
}
