import React from "react"
import { Box, Container } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

export default function OnboardingLayout() {
  return (
    <Box minHeight="100vh" alignItems="center" backgroundColor="gray.800">
      <Container minHeight="100vh" alignItems="center">
        <Outlet />
      </Container>
    </Box>
  )
}
