import { User } from "firebase/auth"
import React, { createContext } from "react"
import { UserData } from "@epicbrief/shared/types/index"

type AuthContextType = {
  currentUser: User | null // The data we get from firebase
  currentUserData: UserData | null // The data that lives in our db
  currentUserIsOrgAdmin: boolean
  refreshUserData: () => Promise<void>
  refreshUserAndOrg: () => Promise<void>
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>
  signInWithGoogle: () => Promise<void>
  signInWithMicrosoft: () => Promise<void>
  signInWithSaml: (provider: string) => Promise<void>
  sendSignInEmail: (email: string) => Promise<void>
  completeEmailSignIn: () => Promise<void>
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export default AuthContext
