import React, { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import BlueLayout from "../Layouts/BlueLayout"
import OnboardingLayout from "../Layouts/OnboardingLayout"
import { ROUTES } from "../utils/constants"
import { getApiClient } from "../api"
import AuthContext from "../AuthContext"

const onboardingRoutes = [ROUTES.LOGIN, ROUTES.LANDING, ROUTES.SIGN_IN_EMAIL]

function PublicRoute() {
  const location = useLocation()
  const code = new URLSearchParams(location.search).get("code")
  const { currentUser } = useContext(AuthContext)

  useEffect(() => {
    async function connectZoomWithRecall() {
      if (code && currentUser) {
        const apiClient = getApiClient(currentUser)
        await apiClient.recall.connectZoomAuthWithRecall(code)
      }
    }
    connectZoomWithRecall()
  }, [code, currentUser])

  if (onboardingRoutes.includes(location.pathname)) return <OnboardingLayout />

  return <BlueLayout />
}

export default React.memo(PublicRoute)
