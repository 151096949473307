import { initializeApp } from "firebase/app"
import { connectAuthEmulator, getAuth } from "firebase/auth"
import {
  connectFirestoreEmulator,
  initializeFirestore,
} from "firebase/firestore"

const firebaseConfig = {
  apiKey: process.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VITE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VITE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VITE_APP_FIREBASE_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)

// if local use default database
const firestore =
  process.env.NODE_ENV === "development"
    ? initializeFirestore(firebaseApp, {})
    : initializeFirestore(firebaseApp, {}, "eu-db")

const auth = getAuth(firebaseApp)

if (process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(firestore, "localhost", 8080)
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true })
}

export { firestore, auth }
