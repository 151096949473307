import React from "react"
import { NavLink, NavLinkProps } from "react-router-dom"

export default React.forwardRef(
  (props: NavLinkProps, ref: React.Ref<HTMLAnchorElement>) => (
    <NavLink
      ref={ref}
      {...props}
      style={{ textDecoration: "none", color: "inherit" }}
    />
  ),
)
