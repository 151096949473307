import {
  Avatar,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem as ChakraMenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import React, { useContext } from "react"
import { EpicbriefLogo } from "../Images/EpicbriefLogo"
import { EpicbriefLogoMobile } from "../Images/EpicbriefLogoMobile"
import { chromeRuntimeAvailable } from "../utils/browser"
import { auth } from "../firebase-config"
import { ROUTES } from "../utils/constants"
import MenuItems from "./MenuItems"
import { useViewport } from "../utils/helpers"
import StyledNavLink from "./StyledNavLink"
import AuthContext from "../AuthContext"
import useMenuItems from "../utils/useMenuItems"

export function MenuContent({
  isMobile,
  handleSignOut,
  userMetadata: { userEmail, displayName, photoUrl },
}: {
  isMobile: boolean
  userMetadata: {
    userEmail: string
    displayName: string
    photoUrl: string
  }
  handleSignOut: () => void
}) {
  const { topMenuItems, bottomMenuItems } = useMenuItems()

  return (
    <Flex
      width="100%"
      justifyContent="space-between"
      height="100%"
      direction="column"
    >
      <Flex p="3" width="100%" alignItems="flex-start" direction="column">
        <Button
          variant="menu"
          size="sm"
          to={ROUTES.LANDING}
          leftIcon={
            isMobile ? (
              <EpicbriefLogoMobile height="18px" />
            ) : (
              <EpicbriefLogo height="18px" />
            )
          }
          pb="8"
          pt="6"
          as={StyledNavLink}
        />
        <MenuItems menuItems={topMenuItems} />
      </Flex>
      <Flex width="100%" direction="column">
        <Flex width="100%" p="3" direction="column">
          <MenuItems menuItems={bottomMenuItems} />
        </Flex>
        <Flex
          borderTop="solid 1px"
          borderColor="gray.200"
          w="100%"
          height="66px"
          px="4"
          _hover={{ background: "gray.200" }}
          gap="3"
        >
          <Menu variant="popover">
            <MenuButton overflow="hidden">
              <Flex>
                <Avatar
                  src={photoUrl}
                  color="blue.600"
                  bg="blue.100"
                  boxSize={!isMobile ? "34px" : "26px"}
                  name={displayName}
                />
                {!isMobile && (
                  <Text fontSize="sm" isTruncated as="div">
                    {userEmail}
                  </Text>
                )}
              </Flex>
            </MenuButton>
            <MenuList rounded="8" boxShadow="sm" mb="-16px">
              <ChakraMenuItem
                onClick={handleSignOut}
                color="red"
                fontWeight="semibold"
              >
                Sign out
              </ChakraMenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  )
}

function MainMenu() {
  const { isMobile } = useViewport()
  const { currentUser } = useContext(AuthContext)

  const onSignOut = async () => {
    await auth.signOut()
    if (chromeRuntimeAvailable()) {
      await chrome.runtime.sendMessage("ngmlnhealdbgaillohceeingecoemapg", {
        action: "signOut",
      })
    }
  }

  return (
    <Box
      w={isMobile ? "60px" : "225px"}
      top={0}
      bg="gray.50"
      borderRightWidth="1px"
      borderRightColor="gray.200"
    >
      <MenuContent
        isMobile={isMobile}
        userMetadata={{
          userEmail: currentUser?.email ?? "",
          displayName: currentUser?.displayName ?? "",
          photoUrl: currentUser?.photoURL ?? "",
        }}
        handleSignOut={onSignOut}
      />
    </Box>
  )
}

export default MainMenu
