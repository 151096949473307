import * as React from "react"
import { Flex, Box } from "@chakra-ui/react"
import { useMediaQuery } from "react-responsive"
import { Link, useLocation } from "react-router-dom"
import { IS_MOBILE_MEDIA_QUERY } from "../utils/constants"
import { EpicbriefLogo } from "../Images/EpicbriefLogo"

export default function Header() {
  const isMobile = useMediaQuery(IS_MOBILE_MEDIA_QUERY)

  const { pathname } = useLocation()
  const url = pathname.slice(1)
  const pathsWithoutLoginBtns = ["register", "login", "onboarding"]
  const hasLoginButtons = !pathsWithoutLoginBtns.includes(url)

  return (
    <Flex
      as="header"
      flexShrink="0"
      justifyContent="space-between"
      pt="1rem"
      pl="0"
      pr="3%"
      w="100%"
    >
      <Box w="33%">
        <Link to="/#">
          <Flex w="100%" justifyContent="center">
            <EpicbriefLogo height="24" />
          </Flex>
        </Link>
      </Box>

      <Flex justifyContent="flex-end" alignItems="center">
        {isMobile || !hasLoginButtons ? null : (
          <Box
            p="10px 20px"
            color="#fff"
            border="1px solid #fff"
            borderRadius="8px"
            mr="20px"
          >
            <Link to="/register" aria-current="page">
              Try now
            </Link>
          </Box>
        )}
        {hasLoginButtons && (
          <Box
            p="10px 20px"
            color="#fff"
            border="1px solid #fff"
            borderRadius="8px"
          >
            <Link to="/login" aria-current="page">
              Login
            </Link>
          </Box>
        )}
      </Flex>
    </Flex>
  )
}
