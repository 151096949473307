import { Flex, Spinner } from "@chakra-ui/react"
import { UserData } from "@epicbrief/shared/types/index"
import {
  GoogleAuthProvider,
  isSignInWithEmailLink,
  OAuthProvider,
  onAuthStateChanged,
  SAMLAuthProvider,
  sendSignInLinkToEmail,
  signInWithCredential,
  signInWithEmailLink,
  signInWithPopup,
  User,
} from "firebase/auth"
import React, { useEffect, useMemo, useState } from "react"
import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from "@react-oauth/google"
import AuthContext from "./AuthContext"
import { orgs, users } from "./controllers/database"
import { auth } from "./firebase-config"
import { Heap } from "./types/Heap"
import { chromeRuntimeAvailable } from "./utils/browser"
import { SIGN_IN_EMAIL_KEY } from "./utils/constants"
import { toastError } from "./utils/toast"

declare global {
  interface Window {
    heap: Heap
  }
}

export function AuthProvider({
  children,
}: {
  children: React.ReactNode
}): React.JSX.Element {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [currentUserData, setUserData] = useState<UserData | null>(null)
  const [currentUserIsOrgAdmin, setCurrentUserIsOrgAdmin] =
    useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const setUser = async (user: User) => {
    const userDataFromDb = await users.get(user.uid)
    setUserData(userDataFromDb)
    setCurrentUser(user)
    setIsLoaded(true)
    setIsAuthenticated(true)
  }

  const googleProvider = new GoogleAuthProvider()
  const signInWithGoogle = async () => {
    try {
      const userCredential = await signInWithPopup(auth, googleProvider)
      await setUser(userCredential.user)
    } catch (error) {
      console.error("Error signing in with Google: ", error)
    }
  }

  const handleGoogleLoginSuccess = async (response: CredentialResponse) => {
    const credential = GoogleAuthProvider.credential(response.credential)

    try {
      const userCredential = await signInWithCredential(auth, credential)
      await setUser(userCredential.user)
    } catch (error) {
      console.error("Error signing in with Google: ", error)
    }
  }

  const microsoftProvider = new OAuthProvider("microsoft.com")
  microsoftProvider.setCustomParameters({ prompt: "select_account" })
  const signInWithMicrosoft = async () => {
    try {
      const userCredential = await signInWithPopup(auth, microsoftProvider)
      await setUser(userCredential.user)
    } catch (error) {
      console.error("Error signing in with Microsoft: ", error)
    }
  }

  const signInWithSaml = async (provider: string) => {
    const samlAuthProvider = new SAMLAuthProvider(provider)
    const userCredential = await signInWithPopup(auth, samlAuthProvider)
    await setUser(userCredential.user)
  }

  const sendSignInEmail = async (email: string) => {
    const url = `${window.location.origin}/sign-in-email`
    const actionCodeSettings = {
      url,
      handleCodeInApp: true,
    }
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings)
      window.localStorage.setItem(SIGN_IN_EMAIL_KEY, email)
    } catch (error) {
      toastError(error)
    }
  }

  const completeEmailSignIn = async () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = window.localStorage.getItem(SIGN_IN_EMAIL_KEY)
      if (!email) {
        return
      }
      try {
        const userCredential = await signInWithEmailLink(
          auth,
          email,
          window.location.href,
        )
        await setUser(userCredential.user)
        window.localStorage.removeItem(SIGN_IN_EMAIL_KEY)
      } catch (error) {
        toastError(error)
      }
    }
  }

  const refreshUserData = async () => {
    if (!currentUser) return
    const userData = await users.get(currentUser.uid)
    setUserData(userData)
  }

  const refreshUserAndOrg = async () => {
    if (!currentUser) return
    const userData = await users.get(currentUser.uid)
    if (!userData) return
    setUserData(userData)

    const org = await orgs.get(userData.orgRef)
    if (org?.adminRefs.includes(currentUser.uid)) {
      setCurrentUserIsOrgAdmin(true)
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && user.email) {
        window.heap.identify(user.email)
        window.heap.addUserProperties({
          email: user.email,
        })

        // Send auth info to browser extension
        if (chromeRuntimeAvailable()) {
          await chrome.runtime.sendMessage("ngmlnhealdbgaillohceeingecoemapg", {
            action: "userSession",
            user,
          })
        }

        const userDataFromDb = await users.get(user.uid)
        setUserData(userDataFromDb)
        setCurrentUser(user)
        setIsLoaded(true)
        setIsAuthenticated(true)

        if (userDataFromDb?.orgRef) {
          const org = await orgs.get(userDataFromDb?.orgRef)
          if (org?.adminRefs.includes(user.uid)) {
            setCurrentUserIsOrgAdmin(true)
          }
        }

        // for now so that tests pass
      } else {
        setCurrentUser(user)
        setIsLoaded(true)
        setIsAuthenticated(false)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const authContextValues = useMemo(
    () => ({
      currentUser,
      currentUserData,
      refreshUserData,
      refreshUserAndOrg,
      currentUserIsOrgAdmin,
      setIsLoaded,
      signInWithGoogle,
      signInWithMicrosoft,
      signInWithSaml,
      sendSignInEmail,
      completeEmailSignIn,
    }),
    [currentUser, currentUserData, currentUserIsOrgAdmin],
  )

  if (!isLoaded) {
    return (
      <Flex w="100vw" h="100vh" justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    )
  }

  return (
    <AuthContext.Provider value={authContextValues}>
      <GoogleOAuthProvider
        clientId={
          process.env.VITE_APP_GOOGLE_CALENDAR_OAUTH_CLIENT_ID || "missing"
        }
      >
        {children}
        {!isAuthenticated && (
          <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={() => console.error("Error signing in with Google")}
            useOneTap
            theme="filled_black"
          />
        )}
      </GoogleOAuthProvider>
    </AuthContext.Provider>
  )
}
