import React from "react"
import ReactDOM from "react-dom/client"
import { PostHogProvider } from "posthog-js/react"
import { PostHogConfig } from "posthog-js"
import App from "./App"

const options: Partial<PostHogConfig> = {
  api_host: process.env.VITE_APP_PUBLIC_POSTHOG_HOST,
  session_recording: {
    maskTextSelector: "*",
  },
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.VITE_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>,
)
