import React from "react"
import { Container, Flex } from "@chakra-ui/react"
import { Outlet, useLocation } from "react-router-dom"
import MainMenu from "../Components/MainMenu"
import PageHeader from "../Components/PageHeader"
import { ROUTES } from "../utils/constants"
import SettingsTabs from "../Components/SettingsTabs"
import { useMeetings } from "../controllers/meetings"

const settingsRoutes = Object.values(ROUTES.SETTINGS)

export default function FullHeightScrollLayout() {
  const location = useLocation()
  const { meetings } = useMeetings()

  const isSettingsPage = settingsRoutes.some((route) =>
    location.pathname.startsWith(route),
  )
  const isEmptyMeetingsPage =
    location.pathname.startsWith(ROUTES.MEETINGS) && meetings?.length === 0

  return (
    <Container
      minHeight="100vh"
      maxWidth="100%"
      height="100vh"
      overflow="hidden"
    >
      <Flex flex={1}>
        <MainMenu />
        {/* Always show scroll to keep the size fixed despite content */}
        <Flex as="main" direction="column" flex={1} overflowY="scroll">
          {!isEmptyMeetingsPage && <PageHeader />}
          {isSettingsPage && <SettingsTabs />}
          <Outlet />
        </Flex>
      </Flex>
    </Container>
  )
}
