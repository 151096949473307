import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export default function DealIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M5.46875 5.46875C5.46875 5.2947 5.53789 5.12778 5.66096 5.00471C5.78403 4.88164 5.95095 4.8125 6.125 4.8125H7.875C8.04905 4.8125 8.21597 4.88164 8.33904 5.00471C8.46211 5.12778 8.53125 5.2947 8.53125 5.46875C8.53125 5.6428 8.46211 5.80972 8.33904 5.93279C8.21597 6.05586 8.04905 6.125 7.875 6.125H6.125C5.95095 6.125 5.78403 6.05586 5.66096 5.93279C5.53789 5.80972 5.46875 5.6428 5.46875 5.46875ZM12.9062 3.71875V10.7188C12.9062 11.0088 12.791 11.287 12.5859 11.4921C12.3808 11.6973 12.1026 11.8125 11.8125 11.8125H2.1875C1.89742 11.8125 1.61922 11.6973 1.4141 11.4921C1.20898 11.287 1.09375 11.0088 1.09375 10.7188V3.71875C1.09375 3.42867 1.20898 3.15047 1.4141 2.94535C1.61922 2.74023 1.89742 2.625 2.1875 2.625H4.15625V2.1875C4.15625 1.78139 4.31758 1.39191 4.60474 1.10474C4.89191 0.817578 5.28139 0.65625 5.6875 0.65625H8.3125C8.71861 0.65625 9.10809 0.817578 9.39526 1.10474C9.68242 1.39191 9.84375 1.78139 9.84375 2.1875V2.625H11.8125C12.1026 2.625 12.3808 2.74023 12.5859 2.94535C12.791 3.15047 12.9062 3.42867 12.9062 3.71875ZM5.46875 2.625H8.53125V2.1875C8.53125 2.12948 8.5082 2.07384 8.46718 2.03282C8.42616 1.9918 8.37052 1.96875 8.3125 1.96875H5.6875C5.62948 1.96875 5.57384 1.9918 5.53282 2.03282C5.4918 2.07384 5.46875 2.12948 5.46875 2.1875V2.625ZM2.40625 3.9375V5.86414C3.82241 6.61053 5.39919 7.00041 7 7C8.60088 7.00037 10.1777 6.6103 11.5938 5.86359V3.9375H2.40625ZM11.5938 10.5V7.32484C10.1498 7.97602 8.58395 8.31268 7 8.3125C5.41604 8.31278 3.85013 7.97611 2.40625 7.32484V10.5H11.5938Z"
      />
    </Icon>
  )
}
