import {
  SummaryBlock,
  SummaryTemplate,
  SummaryTemplateField,
} from "@epicbrief/shared/types/index"
import { useEffect, useState } from "react"
import { v4 as uuid } from "uuid"

export function summaryTemplateFromSummary(
  summary: SummaryBlock[],
  ownerRefs: SummaryTemplate["ownerRefs"],
): SummaryTemplate {
  // Create template fields from summary block
  const fields: SummaryTemplateField[] =
    summary.map(
      ({ name, question, blockType, sectionToSearch, speakersToSearch }) => {
        return {
          name,
          question,
          blockType,
          sectionToSearch,
          speakersToSearch,
        }
      },
    ) ?? []

  // Populate the rest of the fields
  const summaryTemplate: SummaryTemplate = {
    id: uuid(),
    name: "",
    instructions: "",
    ownerRefs,
    teamRefs: [], // TODO: Handle this when implement teams
    fields,
    description: "",
  }

  return summaryTemplate
}

export async function jsonToText(file: Blob): Promise<string> {
  const fReader = new FileReader()
  fReader.readAsText(file)

  return new Promise<string>((resolve, reject) => {
    fReader.onloadend = (event) => {
      if (event.target?.result) {
        resolve(event.target?.result as string)
      } else {
        reject(new Error("Failed to parse file."))
      }
    }
  })
}

export function useViewport() {
  const [width, setWidth] = useState(window.innerWidth)

  const isMobile = width < 768

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  return { width, isMobile }
}
