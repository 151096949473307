import {
  Badge,
  Button,
  Flex,
  Heading,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"
import { useLocation } from "react-router-dom"
import { BotJoinOption, FeatureFlagKey } from "@epicbrief/shared/types"
import { GearSix } from "@phosphor-icons/react"
import useMenuItems from "../utils/useMenuItems"
import SettingsDrawer from "./Home/SettingsDrawer"
import { botJoinOptions } from "./Home/SettingsDrawer/options"
import { useFeatureFlag } from "../utils/featureFlags"
import { useBotJoinOption } from "./Home/SettingsDrawer/useBotJoinOption"

function PageHeader() {
  const { pathname } = useLocation()
  const { routeLabels } = useMenuItems()
  const {
    isOpen: isSettingsDrawerOpen,
    onOpen: onSettingsDrawerOpen,
    onClose: onSettingsDrawerClose,
  } = useDisclosure()

  const { botJoinOption, setBotJoinOption } = useBotJoinOption()

  const isBotJoinSettingsEnabled = useFeatureFlag(
    FeatureFlagKey.botJoinSettings,
  )

  const getBotJoinOptionDetails = (option: BotJoinOption) =>
    botJoinOptions.find(({ value }) => value === option)?.title

  let title = routeLabels[pathname]

  // Fallback if we don't have a title
  if (!title) {
    const segments = pathname.split("/")
    const firstSegment = segments.at(1)
    /**
     * We grab the first part of the path and show it upper cased:
     * /settings/billing/ -> "Settings"
     * /meetings/ -> "Meetings"
     */
    title = firstSegment
      ? firstSegment.charAt(0).toUpperCase() + firstSegment.slice(1)
      : ""
  }

  // meetings show the meeting name
  if (
    title === "Meeting" ||
    title === "Connect-slack" ||
    title === "Templates"
  ) {
    return null
  }

  if (title === "Home") {
    return (
      <Flex justifyContent="start" alignItems="center">
        <Heading
          w="100%"
          fontSize="2xl"
          fontWeight="bold"
          padding="20px 16px"
          margin="0"
          lineHeight="100%"
          color="gray.800"
          borderBottom="solid 1px"
          borderColor="gray.200"
        >
          {title}
          {isBotJoinSettingsEnabled && (
            <Button
              leftIcon={<Icon as={GearSix} />}
              size="sm"
              fontSize="14px"
              variant="secondary"
              float="right"
              onClick={onSettingsDrawerOpen}
            >
              Epicbrief joins:
              <Badge
                color="#F3F4F6"
                padding="2px 8px 2px 8px"
                textColor="black"
                marginLeft="8px"
                borderRadius="6"
                textTransform="none"
              >
                <Text
                  fontSize="14px"
                  variant="secondary"
                  padding="0"
                  fontWeight="600"
                >
                  {getBotJoinOptionDetails(botJoinOption)}
                </Text>
              </Badge>
            </Button>
          )}
        </Heading>

        <SettingsDrawer
          isOpen={isSettingsDrawerOpen}
          onClose={onSettingsDrawerClose}
          existingJoinOption={botJoinOption}
          setExistingJoinOption={setBotJoinOption}
        />
      </Flex>
    )
  }

  return (
    <Flex justifyContent="start" alignItems="center">
      <Heading
        w="100%"
        fontSize="2xl"
        fontWeight="bold"
        padding="24px 48px"
        margin="0"
        lineHeight="100%"
        color="gray.800"
      >
        {title}
      </Heading>
    </Flex>
  )
}

export default PageHeader
