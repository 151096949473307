import { Meeting } from "@epicbrief/shared/types/index"
import { useContext, useEffect } from "react"
import { create } from "zustand"
import { Unsubscribe } from "firebase/auth"
import AuthContext from "../AuthContext"
import * as db from "./database"
import { toastError } from "../utils/toast"

/**
 * This file has 2 layers. A simple local store, all in sync and a controller
 * that uses the store and handles staying in sync with the database.
 */

interface MeetingsState {
  meetings: Meeting[]
  meetingsLoaded: boolean
  setMeetingsLoaded: (value: boolean) => void
  update: (newMeeting: Partial<Meeting>) => void
  setAll: (newMeetings: Meeting[]) => void
  remove: (id: Meeting["id"]) => void
}

const useMeetingsStore = create<MeetingsState>((set) => ({
  meetings: [],
  meetingsLoaded: false,
  setMeetingsLoaded: (value: boolean) => set(() => ({ meetingsLoaded: value })),
  remove: (idToRemove: Meeting["id"]) => {
    set((state) => {
      return {
        ...state,
        meetings: state.meetings.filter(({ id }) => id !== idToRemove),
      }
    })
  },
  update: (newMeeting: Partial<Meeting>) =>
    set((state) => {
      const index = state.meetings.findIndex(({ id }) => id === newMeeting.id)
      const newMeetings = [...state.meetings]
      const meetingBeforeUpdate = state.meetings.find(
        ({ id }) => id === newMeeting.id,
      )
      if (!meetingBeforeUpdate) return state

      const updatedMeeting: Meeting = { ...meetingBeforeUpdate, ...newMeeting }
      newMeetings[index] = updatedMeeting

      return { ...state, meetings: newMeetings }
    }),
  setAll: (newMeetings: Meeting[]) =>
    set((state) => {
      return { ...state, meetings: newMeetings }
    }),
}))

export const useMeetings = () => {
  const { currentUser } = useContext(AuthContext)
  const {
    meetings,
    setAll,
    update,
    remove,
    meetingsLoaded,
    setMeetingsLoaded,
  } = useMeetingsStore()

  const initMeetings = async () => {
    let unsubscribeFromMeetings: Unsubscribe | undefined

    try {
      unsubscribeFromMeetings = await db.meetings.subscribeToListFiltered(
        (mappedDocuments) => {
          setAll(mappedDocuments)
          setMeetingsLoaded(true)
        },
        {
          field: "ownerRefs",
          opStr: "array-contains",
          value: currentUser?.uid,
        },
      )
    } catch (err) {
      toastError(err)
    }

    return unsubscribeFromMeetings
  }

  const updateMeeting = (meeting: Partial<Meeting>) => {
    if (!meeting.id) {
      toastError("Missing id, cant update meeting.")
      return
    }
    update(meeting)
    try {
      db.meetings.update(meeting)
    } catch (err) {
      toastError("Failed to update. Syncing all meetings from db")
      initMeetings()
    }
  }

  const getMeeting = (idToFind: Meeting["id"]) => {
    return meetings.find(({ id }) => id === idToFind)
  }

  const deleteMeetingField = async (
    meetingId: Meeting["id"],
    field: keyof Meeting,
  ) => {
    const meeting = getMeeting(meetingId)
    if (!meeting) {
      toastError("Meeting not found. Can't delete field.")
      return
    }

    update({
      ...meeting,
      summary: undefined,
    })
    try {
      await db.meetings.deleteField(meetingId, field)
    } catch (err) {
      toastError("Failed to update meeting.")
      initMeetings()
    }
  }

  const addMeeting = async (newMeeting: Omit<Meeting, "id">) => {
    try {
      const newMeetingId = await db.meetings.add(newMeeting)
      const newMeetings = [{ ...newMeeting, id: newMeetingId }, ...meetings]
      setAll(newMeetings)
      return newMeetingId
    } catch (err) {
      toastError("Failed to add. Syncing all meetings from db.")
      initMeetings()
      return null
    }
  }

  const deleteMeeting = async (id: Meeting["id"]) => {
    try {
      remove(id)
      return await db.meetings.delete(id)
    } catch (err) {
      toastError("Failed to delete. Syncing all meetings from db.")
      initMeetings()
      return null
    }
  }

  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined

    const init = async () => {
      unsubscribe = await initMeetings()
    }

    if (!meetingsLoaded) {
      init()
    }

    return () => {
      unsubscribe?.()
    }
  }, [])

  return {
    meetings,
    getMeeting,
    updateMeeting,
    addMeeting,
    deleteMeeting,
    deleteMeetingField,
    meetingsLoaded,
  }
}
