import React from "react"
import { Link } from "react-router-dom"
import { Flex, Box } from "@chakra-ui/react"
import { EpicbriefLogo } from "../Images/EpicbriefLogo"

export default function Footer() {
  return (
    <Flex
      flexShrink="0"
      as="footer"
      w="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box w="33%">
        <Link to="/#">
          <Flex w="100%" justifyContent="center">
            <EpicbriefLogo height="24" />
          </Flex>
        </Link>
      </Box>

      <Flex w="33%" justifyContent="center">
        <Box m="0 1rem">
          <Link to="/security">Security</Link>
        </Box>
        <Box m="0 1rem">
          <Link to="https://epicbrief.com/terms">Terms & Conditions</Link>
        </Box>
        <Box m="0 1rem">
          <Link to="https://epicbrief.com/privacy">Privacy Policy</Link>
        </Box>
      </Flex>
      <Box w="33%" textAlign="center">
        &copy; epicbrief
      </Box>
    </Flex>
  )
}
